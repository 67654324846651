import Vue from "vue";
import Vuex from "vuex";
import { firma_bilgileri, config_cagir } from "@/query/productGroups";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    firma_bilgisi: null,
    apikey: null,
    secretkey: null,
    apiurl: null,
    langue: "tr",
  },
  mutations: {
    setFirmaBilgileri(state, firma_bilgisi) {
      state.firma_bilgisi = firma_bilgisi;
    },
    setApiKey(state, apikey) {
      state.apikey = apikey;
    },
    setScretKey(state, secretkey) {
      state.secretkey = secretkey;
    },
    setApiUrl(state, apiurl) {
      state.apiurl = apiurl;
    },
  },
  actions: {
    async fetchFirmaBilgileri({ commit }) {
      const bilgi = await firma_bilgileri();
      const bilgiler = bilgi[0];
      commit("setFirmaBilgileri", bilgiler);
    },
    async fetchConfigBilgileri({ commit }) {
      const bilgi = await config_cagir();
      commit("setApiUrl", bilgi.apiUrl);
      commit("setApiKey", bilgi["apiKeys"].appkey);
      commit("setScretKey", bilgi["apiKeys"].secretkey);
    },
  },
});
