<template>
  <div id="app">
    <v-app id="inspire">
      <Detay
        :modal="tetikle"
        :model="giden_model"
        @hideDialog="tetikle = false"
      ></Detay>
      <v-container class="scroll-y">
        <v-layout align-center justify-center>
          <v-flex xs12>
            <v-sheet color="transparent" max-width="800">
              <v-slide-group center-active>
                <v-slide-item
                  v-for="gruplar in gelen_urun_gruplari.data"
                  :key="gruplar.id"
                >
                  <v-card
                    :color="gruplar ? undefined : 'grey lighten-2'"
                    class="ma-1"
                    width="100"
                    max-height="90%"
                    @click="secildi(gruplar)"
                  >
                    <v-img
                      height="60%"
                      :src="`${$store.state.apiurl}/${gruplar?.attributes?.Image}`"
                    ></v-img>
                    <v-card-actions
                      class="mt-2 text-center text-caption font-weight-black"
                      style="
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-align: left;
                        display: block;
                        width: 100%;
                      "
                    >
                      {{ gruplar?.attributes?.Name }}
                    </v-card-actions>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-sheet>

            <v-divider class="mx-4"></v-divider>

            <div>
              <v-row align="center" justify="center" class="mt-5">
                <span class="white--text text-h5"
                  ><b>{{ secilen_urun_grubu }}</b></span
                ></v-row
              >
            </div>
            <v-row dense class="mt-5">
              <v-card
                class="mx-auto my-2"
                min-width="100%"
                v-for="urunler in gelen_urunler"
                :key="urunler.id"
                outlined
                @click="urun_detay(urunler)"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="font-weight-black">
                      {{ urunler?.attributes?.Name }}
                    </div>
                    <!-- <v-list-item-title class="text-h5 mb-1">
                      {{ urunler?.attributes?.Name }}
                    </v-list-item-title> -->
                    <v-list-item-subtitle
                      class="text-caption mt-1"
                      v-if="urunler?.attributes?.Description"
                    >
                      {{
                        urunler?.attributes?.Description
                      }}</v-list-item-subtitle
                    >
                    <v-divider></v-divider>

                    <div v-if="urunler?.attributes?.Level1.length > 0">
                      <v-list-item-subtitle
                        v-for="item in urunler?.attributes?.Level1"
                        :key="item.level1_id"
                        class="text-caption"
                      >
                        {{ item.level1_Description }}
                        <font size="2">
                          <b
                            ><a class="orange--text"
                              >{{ item.level1_Price }} ₺</a
                            ></b
                          >
                        </font>
                      </v-list-item-subtitle>
                    </div>
                  </v-list-item-content>

                  <v-list-item-avatar
                    tile
                    size="100"
                    color="grey"
                    v-if="urunler?.attributes?.Image"
                  >
                    <v-img
                      v-if="urunler?.attributes?.Image"
                      :src="`${$store.state.apiurl}/${urunler?.attributes?.Image}`"
                    ></v-img>
                  </v-list-item-avatar>
                </v-list-item>
                <v-list-item-subtitle
                  class="ml-4"
                  v-if="urunler?.attributes?.Level1.length === 0"
                >
                  <font size="2">
                    <b
                      ><a class="orange--text"
                        >{{ urunler?.attributes?.Price }} ₺</a
                      ></b
                    >
                  </font>
                </v-list-item-subtitle>
              </v-card>
              <!-- <v-col cols="12">
                <v-card
                  v-for="urunler in gelen_urunler"
                  :key="urunler.id"
                  class="mx-auto my-5"
                  max-width="374"
                  @click="urun_detay(urunler)"
                >
                  <v-img
                    v-if="urunler?.attributes?.Image"
                    width="50%"
                    height="50%"
                    contain
                    :src="`${url}/${urunler?.attributes?.Image}`"
                  ></v-img>

                  <v-card-title>
                    <span size="2">
                      <b>{{ urunler?.attributes?.Name }}</b>
                    </span>
                  </v-card-title>

                  <v-card-text>
                    <div v-if="urunler?.attributes?.Description">
                      {{ urunler?.attributes?.Description }}
                    </div>
                    <v-row align="center" class="mx-0">
                      <div
                        v-if="urunler?.attributes?.Price"
                        class="my-4 text-subtitle-1"
                      >
                        <font size="2">
                          <b
                            ><a class="orange--text"
                              >{{ urunler?.attributes?.Price }} ₺</a
                            ></b
                          >
                        </font>
                      </div>
                    </v-row>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>
                </v-card>
              </v-col> -->
            </v-row>
          </v-flex>
        </v-layout>
      </v-container>
    </v-app>
  </div>
</template>

<script>
import { urun_gruplari, urunler } from "../query/productGroups";
import Detay from "./Detay.vue";

export default {
  components: { Detay },
  computed: {
    myStateLangue() {
      return this.$store.state.langue;
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchConfigBilgileri");
    await this.$store.dispatch("fetchFirmaBilgileri");

    this.gelen_urun_gruplari = await urun_gruplari();
    this.gelen_urunler = await urunler(this.gelen_urun_gruplari?.data[0]?.id);
  },
  data: () => ({
    gelen_urun_gruplari: [],
    gelen_urunler: [],
    secilen_urun_grubu: null,
    tetikle: false,
    giden_model: {},
    fab: 1,
  }),
  methods: {
    async secildi(v) {
      this.secilen_urun_grubu = v?.attributes?.Name;
      this.gelen_urunler = await urunler(v?.id);
    },
    urun_detay(v) {
      this.tetikle = true;
      this.giden_model = v;
    },
    onScroll(e) {
      this.fab = e;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    wpclick() {
      window.location.href =
        "https://wa.me/" + this.$store.state.firma_bilgisi?.Gsm;
    },
  },
  watch: {
    async myStateLangue() {
      this.gelen_urun_gruplari = await urun_gruplari();
      this.gelen_urunler = await urunler(this.gelen_urun_gruplari?.data[0]?.id);
      // Burada dil kodları değiştiğinde yapılması istenen işlemleri gerçekleştirebilirsiniz.
    },
  },
};
</script>
