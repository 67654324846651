import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import tr from "vuetify/lib/locale/tr";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { tr },
    current: "tr",
  },
  theme: {
    dark: true,
    themes: {
      light: {
        primary: "#FFD700",
        secondary: "#a51c30",
        accent: "#a7333f",
        error: "#74121d",
        info: "#580c1f",
        success: "#00b485",
        warning: "#ffb107",
      },
    },
  },
});
